import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";


import "../assets/css/bootstrap.css"
import "../assets/css/font-awesome.css"
import "../assets/css/owl.theme.css"
import "../assets/css/animate.css"
import "../assets/css/slick.css"
import "../assets/css/flaticon.css"
import "../assets/css/settings.css"
import "../assets/css/style.css"
import "../assets/css/preset.css"
import "../assets/css/responsive.css"


import MenuWhitePage from "../components/MenuWhitePage/MenuWhitePage"
import SectionTen from "../components/SectionTen/SectionTen"
import Seo from "../components/seo"

const VideoMarketing= () => (
    <>
    <Seo title="About us" />
    <MenuWhitePage/>
    {/* <div className="aboutVid">
        <video autoPlay loop muted id="videoAbout">
            <source src={vid} type="video/mp4"/>
        </video>
    </div> */}
    <div className="perelaxBg1"></div>
    <div className="aboutTop home_page2 videoMarktFirstSec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="abcontentT">
                            <article>
            <h1>Are you a business owner and looking to reach out to more clients and increase your profit?</h1>
            <p> Rhoni Vision Production produced videos for local and major business, organisations and charities.</p>
            <p>Our aim is to optimise your promotional video to reach the maximum impact. </p>
            <p>With our dedicated team we will recommend creative solutions, guide and share our knowledge to tell great stories and make your brand stand out.</p>
            <AnchorLink to="/#contact">
                <button className="aboutusBtn videoMarktBtn">Ready to get started</button>
            </AnchorLink>
        </article>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
    <section className="videoMarktTxt">
        <article>
            <h2>Our prices</h2>
            <p>As much as we think these packages are ideal for most organisations, these are not standar prices, and we'd be pleased to talk to you about any of the packages below if you need a specific type of video production.</p>
            <p>For a precise quote, please contact us.</p>
        </article>
    </section>

    <section className="videoMarktTitle">
        <div className="abBigtitle home_page2">
            <h2 className="lead">MICRO-CONTENT</h2>
        </div>
    </section>
    <section className="videoMarktTxt"> 
    <article>
    <p>This is the excellent package for any social media marketing campaign for businesses trying to boost their audience, brand awareness, and sales.</p>
    <p>Quality and quantity of content go hand in hand, and sticking to a regular strategy not only helps create a
better customer experience, but it also helps build credibility, reputation, and brand trust, which is why a 12-
month commitment is required to obtain outstanding results at a low cost.</p>
</article>
    </section>
    <div className="videoMarktingPrices">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="singleService">
                               
                                <h5 className="lead text-uppercase bold videoMArktServiceType">BASIC</h5>
                                <p><strong>From <sup className="upSmallTxt">£ </sup>349<sup className="upSmallTxt"> /month</sup></strong></p>
                                <p>Package includes:</p>
                                <ul className="packages">
                                    <li>Marketing strategy plan</li>
                                    <li>Two 15 seconds videos or one 30 seconds video every month</li>
                                    <li>4K delivery</li>
                                    <li>Commercial Music License</li>
                                    <li>Free logo animation</li>
                                    <li>&nbsp;</li>
                                    <li>&nbsp;</li>
                                    <li>&nbsp;</li>
                                </ul>
                                <AnchorLink to="/#contact">
                                    <AnchorLink to="/#contact">
                                    <button className="aboutusBtn">CONTACT US</button>
                                </AnchorLink>
                                </AnchorLink>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="singleService">
                                
                                <h5 className="lead text-uppercase bold videoMArktServiceType">PLUS</h5>
                                <p><strong>From <sup className="upSmallTxt">£ </sup>749<sup className="upSmallTxt"> /month</sup></strong></p>
                                <p>Package includes:</p>
                                <ul className="packages">
                                    <li>Marketing strategy plan</li>
                                    <li>Four 15 seconds videos or Two 30 seconds videos every month</li>
                                    <li>One testimonial video every month</li>
                                    <li>Up to two aspect ratios</li>
                                    <li>4K delivery</li>
                                    <li>5 Corporate Photographs every month </li>
                                    <li>Commercial Music License</li>
                                    <li>Free logo animation</li>
                                </ul>
                                <AnchorLink to="/#contact">
                                    <button className="aboutusBtn">CONTACT US</button>
                                </AnchorLink>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="singleService">
                                
                                <h5 className="lead text-uppercase bold videoMArktServiceType">PRO</h5>
                                <p><strong>From <sup className="upSmallTxt">£ </sup>1249<sup className="upSmallTxt"> /month</sup></strong></p>
                                <p>Package includes:</p>
                                <ul className="packages">
                                    <li>Marketing strategy plan</li>
                                    <li>Six 15 seconds videos or Three 30 seconds videos every month</li>
                                    <li>One testimonial video every month</li>
                                    <li>Up to two aspect ratios</li>
                                    <li>4K delivery</li>
                                    <li>10 Corporate Photographs every month </li>
                                    <li>Commercial Music License</li>
                                    <li>Free logo animation</li>
                                </ul>
                                <AnchorLink to="/#contact">
                                    <button className="aboutusBtn">CONTACT US</button>
                                </AnchorLink>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        
                    </div>
                    
                </div>
    </div>
        
    <section className="videoMarktTitle">
        <div className="abBigtitle home_page2">
            <h2 className="lead">Commercial Video</h2>
        </div>
    </section>
    <section className="videoMarktTxt"> 
    <article>
    <p>Commercial or promotional video content has a huge potential for building buzz around your brand and
increasing awareness of what you do and the services you offer.</p>
    <p>A successful commercial attracts a big audience, increases conversion rates and sales, delivers a positive return
on investment, is crucial for SEO, is particularly engaging to mobile users, and transforms ideas into a
compelling storey.</p>
</article>
    </section>
    <div className="videoMarktingPrices">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 oneService">
                            <div className="singleService">
                                <h5 className="lead text-uppercase bold videoMArktServiceType">Commercial Video</h5>
                                <p><strong>From <sup className="upSmallTxt">£ </sup>1499</strong></p>
                                <p>Package includes:</p>
                                <ul className="packages">
                                    <li>Marketing strategy plan</li>
                                    <li>Crew of 3 persons</li>
                                    <li>1-2 cameras</li>
                                    <li>Gimbal</li>
                                    <li>Drone</li>
                                    <li>Up to 8 hours of filming</li>
                                    <li>30 - 60 seconds commercial video</li>
                                    <li>15 second micro-content for social media</li>
                                    <li>Commercial Music license</li>
                                    <li>Voice-Over</li>
                                    <li>2 Revisions</li>
                                    <li>4K delivery</li>
                                </ul>
                                <AnchorLink to="/#contact">
                                    <button className="aboutusBtn">CONTACT US</button>
                                </AnchorLink>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        
                    </div>
                    
                </div>
    </div>
    <section className="videoMarktTitle">
        <div className="abBigtitle home_page2">
            <h2 className="lead">Video Business Card</h2>
        </div>
    </section>
    <section className="videoMarktTxt"> 
    <article>
    <p>To be a commodity in a pricing war, businesses must ensure that they are notably different and unique, so that
people flock to them and become loyal customers. This is accomplished through establishing a personal
connection.</p>
    <p>A video business card is an asset that takes customers on a tour of your company and connects emotionally
with them better than a hundred tour guides and all of your salesmen combined.
</p>
</article>
    </section>
    <div className="videoMarktingPrices">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 oneService">
                            <div className="singleService">
                                <h5 className="lead text-uppercase bold videoMArktServiceType">Video Business Card</h5>
                                <p><strong>From <sup className="upSmallTxt">£ </sup>2499</strong></p>
                                <p>Package includes:</p>
                                <ul className="packages">
                                    <li>Marketing strategy plan</li>
                                    <li>Crew of 3 persons</li>
                                    <li>1-2 cameras</li>
                                    <li>Gimbal/Slider</li>
                                    <li>Drone</li>
                                    <li>Audio recording</li>
                                    <li>Up to 2 days of filming</li>
                                    <li>2-3 minutes video</li>
                                    <li>15 second micro-content for social media</li>
                                    <li>Commercial Music license</li>
                                    <li>Voice-Over</li>
                                    <li>2 Revisions</li>
                                    <li>4K delivery</li>
                                </ul>
                                <AnchorLink to="/#contact">
                                    <button className="aboutusBtn">CONTACT US</button>
                                </AnchorLink>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        
                    </div>
                    
                </div>
    </div>

    <SectionTen/>
    </>
)
export default VideoMarketing